import React from 'react';
import './privacylink.scss';
import { Link } from 'react-router-dom';

const PrivacyLink = () => {
    return (
        <div className="bottom_privacy">
          <Link to="/privacy">Privacy Policy</Link>
        </div>

    )

}

export default PrivacyLink;