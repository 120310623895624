import React from 'react';
import './socials.scss';

const Socials = () => {
    return(
        <>
{/*                 <div className="socials_text">
            <p>Join us on our socials!</p>
        </div> */}
        <div className="socials">
            <a href="https://discord.gg/FRN3STzsfS">
                <img src="img/icons/discordx96.png" alt="" />    
            </a>            
            <a href="https://x.com/ZZZdle">
                <img src="img/icons/twitter96.png" alt="" />    
            </a>
        </div>

        </>

    )

}
export default Socials;