import React from 'react';
import './banner.scss';

const Banner = ({imageUrl, name, link}) => {
    return (
        <div className="banner_wrapper">
             <div className="banner">

                <a href={link}>{name}</a>
                <div className="banner_filter"></div>
                <img src={imageUrl} alt="" />   
            </div>
        </div>

    )

}

export default Banner;