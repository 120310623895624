import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import character from '../../data/impact_data.json';
import daily from '../../data/impact_indice.json';
import './guesscharacter.scss';
import Guess from '../Guess/Guess';
import Socials from '../Socials/Socials';
import Banner from '../Banner/Banner';
import CountdownTimer from '../Countdown/Countdown';
import BannerTitle from '../BannerTitle/BannerTitle';
import PrivacyLink from '../PrivacyLink/PrivacyLink';

const GuessCharacter = () => {
  const [randomChampionIndex, setrandomChampionIndex] = useState(null);
  const [selectedChampionIndex, setselectedChampionIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [guessedChampionsIndex, setGuessedChampionsIndex] = useState([]);
  const [gameFinished, setGameFinished] = useState(false);
  const [resetFlag, setResetFlag] = useState(false);
  const [yesterdayIndex, setYesterdayIndex] = useState(null);

  const getFormattedDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const championNames = Object.keys(character.data);
    if (championNames.length > 0) {
      const today = new Date();
      const startDate = new Date(2024, 5, 19); // June 19th, 2024 (months are zero-based)
      const timeDiff = today.getTime() - startDate.getTime();
      const daysSinceEpoch = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

      // Use modulo operation to ensure index stays within bounds
      const dailyIndex = daysSinceEpoch;
      setrandomChampionIndex(daily.data[dailyIndex]);
      setYesterdayIndex(daily.data[(daysSinceEpoch - 1 + daily.data.length) % daily.data.length]);
    }
  }, [resetFlag]);

  // Load guessedChampionsIndex from cookies when the component mounts
  useEffect(() => {
    try {
      const savedDateCookie = Cookies.get('datecookie');
      const today = getFormattedDate(); // Get today's date in YYYY-MM-DD format

      if (savedDateCookie !== today) {
        // Clear cookies if the date is different
        Cookies.remove('guessedChampionsIndex');
        Cookies.set('datecookie', today, { expires: 7 });
        setGuessedChampionsIndex([]);
        return;
      }

      const savedGuessedChampions = Cookies.get('guessedChampionsIndex');
      if (savedGuessedChampions) {
        const parsedGuessedChampions = JSON.parse(savedGuessedChampions);
        setGuessedChampionsIndex(parsedGuessedChampions);

        // Check if game is finished when loading guessed champions from cookies
        const lastGuessedChampion = parsedGuessedChampions[parsedGuessedChampions.length - 1];
        if (String(lastGuessedChampion) === String(randomChampionIndex)) {
          setselectedChampionIndex(lastGuessedChampion);
          setGameFinished(true);
        }
      }
    } catch (error) {
      console.error('Failed to parse guessed champions from cookies:', error);
    }
  }, [randomChampionIndex]);

  // Save guessedChampionsIndex to cookies whenever it changes
  useEffect(() => {
    if (guessedChampionsIndex.length > 0) {
      try {
        const today = getFormattedDate(); // Get today's date in YYYY-MM-DD format
        Cookies.set('guessedChampionsIndex', JSON.stringify(guessedChampionsIndex), { expires: 7 });
        Cookies.set('datecookie', today, { expires: 7 }); // Save the date of last time guessed
      } catch (error) {
        console.error('Failed to stringify guessed champions for cookies:', error);
      }
    }
  }, [guessedChampionsIndex]);

  const handleReset = () => {
    setGuessedChampionsIndex([]);
    setResetFlag(!resetFlag); // Change resetFlag to trigger useEffect
    setGameFinished(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredChampions = Object.keys(character.data)
    .filter(championName => {
      const championData = character.data[championName];
      if (!championData || !championData.name) return false; // Check if championData or its name is undefined
      return championData.name.toLowerCase().includes(searchTerm.toLowerCase()) && 
      !guessedChampionsIndex.includes(championName); // check if guessed already
    })
    .sort((a, b) => {
      // Check which name starts with the search term first
      const startsWithA = character.data[a].name.toLowerCase().startsWith(searchTerm.toLowerCase());
      const startsWithB = character.data[b].name.toLowerCase().startsWith(searchTerm.toLowerCase());

      if (startsWithA && !startsWithB) return -1; // a comes first
      if (!startsWithA && startsWithB) return 1; // b comes first
      return 0; // else maintain original order
    });

  const handleChampionClick = (championName) => {
    setselectedChampionIndex(championName);
    setSearchTerm(''); // Clear searchbar when a champion is selected
    setGuessedChampionsIndex([...guessedChampionsIndex, championName]);
    if (championName === String(randomChampionIndex)) {
      console.log("you guessed it right");
      setGameFinished(true);
    } else {
      //setGuessedChampionsIndex([...guessedChampionsIndex, championName]);
    }
  };

  if (randomChampionIndex === null) {
    return <div>Loading...</div>;
  }

  return (
    <div className="site_wrapper">
      <div className="background">
        <img src="img/impact/Background_Liyue.jpg" alt="" />
      </div>
      <div className="background_filter"></div>
      <div className="site_margin margin_left">
      </div>
      <div className="site_margin margin_right">
      </div>
      <header>
        <h1>Impactdle</h1>
        <hr />
      </header>
      <main className="site_content">
        <div className="search_wrapper">
          {gameFinished ? (
            <div className="gamefinished">
              <p className="correct">Correct!</p>
              <p>Today's character was: {selectedChampionIndex && character.data[selectedChampionIndex]?.name}</p>
              <CountdownTimer />
              {/* <button onClick={handleReset}>Reset</button> */}
            </div>
          ) : (
            <div className="search_content">
              <div className="search_header">
                <h2 className="" >Guess today's character:</h2>
                <input
                  type="text"
                  placeholder="Search character..."
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </div>
              {searchTerm && filteredChampions.length > 0 && (
                <div className="search_results">
                  <ul className="myDropDown">
                    {filteredChampions.map((championName) => (
                      <li key={championName} onClick={() => handleChampionClick(championName)}>
                        <div className="search_thumb"><img src={"img/impact/" + character.data[championName].thumbnail} alt="" /></div>
                        <div className="search_name">{character.data[championName].name}</div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="guessed_characters">
          {guessedChampionsIndex.length > 0 && (
            <div className="trait_header">
              <div>Character</div>
              <div>Gender</div>
              <div>Stars</div>
              <div>Element</div>
              <div>Weapon</div>
              <div className="borderright">Region</div>
            </div>
          )}

          <>
            {guessedChampionsIndex.map((champion, index) => (
              <Guess key={index} randomChampionIndex={randomChampionIndex} selectedChampion={guessedChampionsIndex[guessedChampionsIndex.length - 1 - index]} />
            ))}
          </>
        </div>
        {/* <div className="reset_button"><button onClick={handleReset}>Reset</button></div> */}
        <div className="yesterday">
          <p>Yesterday's character was</p>
          <p className="yesterday_champ">{character.data[yesterdayIndex]?.name}</p>
        </div>
        <Socials />
        <PrivacyLink />
        <BannerTitle />
        <Banner imageUrl={"img/banners/zzzbanner.jpg"} name={"ZZZdle"} link={"https://zzzdle.com/"}/>
        <Banner imageUrl={"img/banners/honkaibanner.png"} name={"Honkaidle"} link={"https://honkaidle.com/"}/>
      </main>
    </div>
  );
};

export default GuessCharacter;
