import React from 'react';
import './bannertitle.scss';

const BannerTitle = () => {
    return (
        <div className="banner_title">
            <p>Check out our other games!</p>
        </div>

    )

}

export default BannerTitle;