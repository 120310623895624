import './reset.scss';
import './global.scss'
import './App.scss';
import GuessCharacter from './components/GuessCharacter/GuessCharacter';
import {Route, Routes} from 'react-router-dom'
import Privacy from './components/Privacy/Privacy';

function App() {
  return (
    <Routes>
      <Route path="/" element={<GuessCharacter />} />
      <Route path="/privacy" element={<Privacy />} />
    </Routes>

    
  );
}

export default App;
