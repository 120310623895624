import React, { useState, useEffect } from 'react';
import './countdown.scss';

const CountdownTimer = () => {
  const [countdown, setCountdown] = useState('');

  useEffect(() => {
    // Function to calculate and update countdown
    const updateCountdown = () => {
      const now = new Date();
      const currentDay = now.getDate();
      const currentMonth = now.getMonth();
      const currentYear = now.getFullYear();
      const tomorrow = new Date(currentYear, currentMonth, currentDay + 1, 0, 0, 0);
      const timeUntilTomorrow = tomorrow.getTime() - now.getTime();

      if (timeUntilTomorrow <= 0) {
        setCountdown('Next day has started!');
      } else {
        // Convert milliseconds to hours, minutes, seconds
        let seconds = Math.floor((timeUntilTomorrow / 1000) % 60);
        let minutes = Math.floor((timeUntilTomorrow / (1000 * 60)) % 60);
        let hours = Math.floor((timeUntilTomorrow / (1000 * 60 * 60)) % 24);

        // Format hours, minutes, seconds with leading zeros if necessary
        hours = (hours < 10) ? `0${hours}` : hours;
        minutes = (minutes < 10) ? `0${minutes}` : minutes;
        seconds = (seconds < 10) ? `0${seconds}` : seconds;

        setCountdown(`${hours}:${minutes}:${seconds}`);
      }
    };

    // Initial call to update countdown
    updateCountdown();

    // Update countdown every second
    const interval = setInterval(updateCountdown, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <p className="count">Next character in:</p>
      <p className="sec">{countdown}</p>
    </div>
  );
};

export default CountdownTimer;
